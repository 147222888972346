import { updateAutonumerics } from './autonumeric.js';

document.addEventListener("turbolinks:load", function() {
  $(document).trigger('refresh_autonumeric');

  $('#dropdownyear').on('change', function() {
    this.form.submit();
  });

  // when the form is submitted, uncheck all checkboxes on items that are not visible
  $('.uncheck-hidden-checkboxes-on-submit').on('submit', function() {
    const hiddenCheckboxes = $(this).find('input[type=checkbox]:not(:visible)')
    hiddenCheckboxes.each(function() {
      const checkbox = $(this);
      checkbox.prop('checked', false);
    })
  })


  $("#invoice_request_line_items")
    .on('cocoon:after-insert', function() {
      updateAutonumerics();
    })

  $("#additional-items-list")
    .on('cocoon:after-insert', function() {
      updateAutonumerics();
    })

  // automatic calculations for fixed fee items
  const $invoiceRequestLineItems = $("#invoice_request_line_items")
  $invoiceRequestLineItems.find(".percent-of-budgeted-amount").each(function() {
    const $percentOfBudgetedAmount = $(this);
    $percentOfBudgetedAmount.on('change', function(e) {
      const $percentOfBudgetedAmountChanged = $(e.target)
      const amount = $percentOfBudgetedAmountChanged.data('budget-in-cents');

      const percent = $percentOfBudgetedAmountChanged.val().replace(/[^0-9]/g, '');
      const newBudgetedAmountInDollars = ((percent / 100) * amount) / 100;

      $percentOfBudgetedAmountChanged.closest('tr').find('.amount-to-update').val(newBudgetedAmountInDollars);
    })
  })

  $invoiceRequestLineItems.find("tr.kantata-entity").each(function() {
    console.log('looking at line items')
    /// automatic checkbox checking on input change
    const $invoiceRequestLineItem = $(this);
    $invoiceRequestLineItem.find('input:not(.entity-checker), select, textarea').on('change', function() {
      $(this).closest('tr').find('.entity-checker').prop('checked', true);
    })

    // setup expense conversion
    const $amountInDollars = $invoiceRequestLineItem.find(".amount-in-dollars")
    if($amountInDollars.length === 0) {

      // most likely not an expense row, it's a fixed fee item, time entry, etc.
      return;
    }
    const AMOUNT_IN_DOLLARS = floatFromVal($amountInDollars.val())

    const $markupInDollars = $invoiceRequestLineItem.find(".markup-in-dollars")
    const $markupInPercent = $invoiceRequestLineItem.find(".markup-percent")
    const $totalAfterMarkup = $invoiceRequestLineItem.find(".total-after-markup")

    $markupInDollars.on('keyup change', function(e) {
      e.preventDefault();
      e.stopPropagation();

      const markupInDollars = floatFromVal($(this).val())
      console.log('markup in dollars changed', markupInDollars)
      $markupInPercent.val((markupInDollars / AMOUNT_IN_DOLLARS) * 100)
      $totalAfterMarkup.val(AMOUNT_IN_DOLLARS + markupInDollars)
    })

    $markupInPercent.on('keyup change', function(e) {
      e.preventDefault();
      e.stopPropagation();

      const markupInPercent = floatFromVal($(this).val())
      console.log('markup in percent changed', markupInPercent)

      const markupInDollars = (markupInPercent / 100) * AMOUNT_IN_DOLLARS
      $markupInDollars.val(markupInDollars)
      $totalAfterMarkup.val(AMOUNT_IN_DOLLARS + markupInDollars)
    })

    $totalAfterMarkup.on('keyup change', function(e) {
      e.preventDefault();
      e.stopPropagation();

      const totalAfterMarkup = floatFromVal($(this).val())
      console.log('total after markup changed', totalAfterMarkup)
      const markupInDollars = totalAfterMarkup - AMOUNT_IN_DOLLARS
      $markupInDollars.val(markupInDollars)

      $markupInPercent.val((markupInDollars / AMOUNT_IN_DOLLARS) * 100)
    })

  })

  // hide fully billed items unless "Display fully billed items" is checked
  const $displayFullyBilled = $('#display_fully_billed');
  if ($displayFullyBilled.length !== 0) {
    const $body = $('body')
    const DISPLAY_FULLY_BILLED_CLASS = 'display-fully-billed';
    $displayFullyBilled.on('change', function() {
      if (this.checked) {
        $body.addClass(DISPLAY_FULLY_BILLED_CLASS);
      } else {
        $body.removeClass(DISPLAY_FULLY_BILLED_CLASS);
      }
    })
  }

  updateAutonumerics();
});

function floatFromVal(str) {

  if(!str) {
    debugger;
  }

  return parseFloat(str.replace(/[^0-9\.]/g, ''));
}

