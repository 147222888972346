
document.addEventListener("turbolinks:load", function(){
  // needed with nested cocoon forms so they all have unique ids on insertable cocoon elements
  const REPLACE_ME_IN_JS_AFTER_INSERT = /replaceable-guid-[0-9a-f]+/g;
  $('form').on('cocoon:before-insert', function(e, $elementToInsert) {
    const guid = (new Date).getTime().toString(16);
    console.log(`New guid to replace REPLACE_ME_IN_JS_AFTER_INSERT`, guid)

    $elementToInsert.find('.cocoon-replace-id').each(function() {
      let $replaceCocoonId = $(this);
      console.log('.cocoon-replace-id element before', guid, $replaceCocoonId.attr('id'))
      $replaceCocoonId.attr('id', $replaceCocoonId.attr('id').replace(REPLACE_ME_IN_JS_AFTER_INSERT, guid));
      console.log('.cocoon-replace-id element after', guid, $replaceCocoonId.attr('id'))
    })

    $elementToInsert.find('a.add_fields').each(function() {
      let $addItemButton = $(this);
      console.log('a.add_fields before', guid, $addItemButton.attr('data-association-insertion-node'))
      $addItemButton.attr('data-association-insertion-node', $addItemButton.attr('data-association-insertion-node').replace(REPLACE_ME_IN_JS_AFTER_INSERT, guid));
      console.log('a.add_fields after', guid, $addItemButton.attr('data-association-insertion-node'))
    })
  });
})
