document.addEventListener("turbolinks:load", function(){
  const HIDE_CLASS = "hide"

  const $controlsThatFiltersProjects = document.querySelectorAll(".filters-projects")
  $controlsThatFiltersProjects.forEach(($controlThatFiltersProjects) => {
    filterProjectsForFilterProjectControl($controlThatFiltersProjects)

    $controlThatFiltersProjects.addEventListener('change', () => {
      filterProjectsForFilterProjectControl($controlThatFiltersProjects)
    })
  })

  function filterProjectsForFilterProjectControl($controlThatFiltersProjects) {
    const companySelectedId = $controlThatFiltersProjects.value
    const $projectsToFilterQuerySelector = $controlThatFiltersProjects.dataset.projectsToFilter

    const $projectsToFilter = document.querySelectorAll($projectsToFilterQuerySelector)
    $projectsToFilter.forEach(($projectToFilter) => {
      const projectCompanyId = $projectToFilter.dataset.companyId
      if(projectCompanyId === companySelectedId) {
        $projectToFilter.classList.remove(HIDE_CLASS)
      }
      else {
        $projectToFilter.classList.add(HIDE_CLASS)
      }
    })
  }
})
