document.addEventListener("turbolinks:load", function(){
  $('.ui.dropdown.clearable:not(.-lazy-load)').dropdown({
    clearable: true,
    placeholder: "-- select --",
    fullTextSearch: true
  });

  $('.ui.dropdown:not(.-lazy-load)').dropdown({
    placeholder: "-- select --",
    fullTextSearch: true
  });
})
