// expandable cards have a broader use than just execution log inline forms.
// Elements with the classes of view-mode and expanded-view-mode will be toggled
// when an element that class the class card-toggle is clicked. Elements with the
// class edit-mode and expanded-edit-mode will be show when the an element with the class
// edit-card-button is clicked, but expanded-edit-mode will only show if it's been expanded
// (.card-toggle has been clicked)

document.addEventListener("turbolinks:load", function(){
  const EDIT_MODE_CLASS = 'edit-mode'
  const EXPANDED_EDIT_MODE_CLASS = 'expanded-edit-mode'
  const VIEW_MODE_CLASS = 'view-mode'
  const EXPANDED_VIEW_MODE_CLASS = 'expanded-view-mode'

  const $expandableCards = document.querySelectorAll('.expandable-card');
  $expandableCards.forEach(($expandableCard) => {
    const formId = $expandableCard.getAttribute('id')
    const $expandView = $expandableCard.querySelectorAll('.card-toggle')
    $expandView.forEach(($expandView) => {
      $expandView.addEventListener('click', () =>{
        if($expandableCard.classList.contains(VIEW_MODE_CLASS)) {
          $expandableCard.classList.remove(VIEW_MODE_CLASS)
          $expandableCard.classList.add(EXPANDED_VIEW_MODE_CLASS)

          loadFormControls(formId)
        }
        else if($expandableCard.classList.contains(EXPANDED_VIEW_MODE_CLASS)) {
          $expandableCard.classList.remove(EXPANDED_VIEW_MODE_CLASS)
          $expandableCard.classList.add(VIEW_MODE_CLASS)
        }
      })
    })

    const $editButtons = $expandableCard.querySelectorAll('.edit-card-button')
    $editButtons.forEach(($editButton) => {
      $editButton.addEventListener('click', () => {
        if($expandableCard.classList.contains(VIEW_MODE_CLASS) || $expandableCard.classList.contains(EXPANDED_VIEW_MODE_CLASS)) {
          $expandableCard.classList.remove(VIEW_MODE_CLASS)
          $expandableCard.classList.remove(EXPANDED_VIEW_MODE_CLASS)
          $expandableCard.classList.add(EXPANDED_EDIT_MODE_CLASS)

          loadFormControls(formId)
        }
      })
    })

    const $viewButtons = $expandableCard.querySelectorAll('.view-card-button')
    $viewButtons.forEach(($viewButton) => {
      $viewButton.addEventListener('click', () => {
        if($expandableCard.classList.contains(EDIT_MODE_CLASS) || $expandableCard.classList.contains(EXPANDED_EDIT_MODE_CLASS)) {
          $expandableCard.classList.remove(EDIT_MODE_CLASS)
          $expandableCard.classList.remove(EXPANDED_EDIT_MODE_CLASS)
          $expandableCard.classList.add(EXPANDED_VIEW_MODE_CLASS)
        }
      })
    })
  })

  $('.ui.dropdown:not(.-lazy-load)').dropdown();

  $('[data-toggle="tooltip"]').tooltip();
})

function loadFormControls(formId) {
  flatpickr(`#${formId} .flatpickr-input.-lazy-load`, {
    dateFormat: "m/d/Y"
  });

  $(`#${formId} .ui.dropdown.-lazy-load`).dropdown();

  const $projectDropdown = $(`#${formId} [name="project_id"]`)
  if ($projectDropdown) {
    $projectDropdown.change(function(){
      $(`#${formId} .ui.dropdown.clear-on-project-change`).dropdown('clear')
    })
  }
}
