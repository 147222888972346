import moment from 'moment'
import { copyToClipboard } from './helpers'

document.addEventListener("turbolinks:load", function(){
  const $effortEntry = document.getElementById("effort_entry")
  const $clientApprovalForm = document.getElementById("client_approval_form")
  const $effortForm = document.getElementById("effort_form")

  if($effortEntry) {
    $effortEntry.addEventListener("change", updateExecutionLog)
  }

  function updateExecutionLog() {
    if($effortEntry && $clientApprovalForm && $effortForm) {
      if($effortEntry.value == "true") {
        $clientApprovalForm.classList.add("hide")
        $effortForm.classList.remove("hide")
      }
      else {
        $clientApprovalForm.classList.remove("hide")
        $effortForm.classList.add("hide")
      }
    }
  }

  const $executionLogForms = document.querySelectorAll('.execution-log-form')
  $executionLogForms.forEach(($executionLogForm) => {
    const $copyToClipboard = $executionLogForm.querySelector('.copy-to-clipboard')
    if($copyToClipboard) {
      $copyToClipboard.addEventListener('click', (e) => {
        const $shareableLinkInput = $executionLogForm.querySelector('.shareable-link-input')
        copyToClipboard($shareableLinkInput.value)
      })
    }

    const $copyToClipboardButtons = $executionLogForm.querySelectorAll('.show-shareable-link')
    if($copyToClipboardButtons) {
      $copyToClipboardButtons.forEach(($copyToClipboardButton) => {
        $copyToClipboardButton.addEventListener('click', (event) => {
          $executionLogForm.classList.toggle('show-shareable-link')
        })
      })
    }
  })

  function getCurrentFilterValues(filterName) {
    return Array.from(document.querySelectorAll(`#card-filter [name='${filterName}[]']`))
      .filter((s) => s.checked)
      .map((s) => s.value)
  }

  updateExecutionLog()
})
