$(document).on('turbolinks:load', function() {
  document.querySelectorAll(".phase-card").forEach(($phaseCard) => {
    $phaseCard.querySelectorAll(".card-toggle").forEach(($cardToggle) => {
      $cardToggle.addEventListener('click', () => togglePhase($phaseCard.dataset.phaseId, true) )
    })
  })

  const href = window.location.href;
  if(href.substr("open_phase=true") !== -1 && href.substr("#phase_") !== -1) {
    const href_parts = href.split("#phase_");
    const phase_id = href_parts[1];

    togglePhase(phase_id, true);
  }

  $(document).on('show.bs.modal', function () {
    var date = new Date();
    $('.datepicker').val(date.getFullYear().toString() + '-' + (date.getMonth() + 1).toString().padStart(2, 0) +
    '-' + date.getDate().toString().padStart(2, 0));
  })
})

// Expand Phase Cards on Project Detail View
function expandAllPhases() {
  const els = document.querySelectorAll('.phase-card-row')

  for (let i = 0; i < els.length; i++) {
    const class_arr = els[i].classList.value.split(" ");
    // Get ID from class
    const id = class_arr.filter(function (classString) {
      return classString.includes("phase-") && !classString.includes("phase-card")
    })[0].split('phase-')[1];

    const el = document.querySelector('.phase-' + id);

    expandPhase(el)
  }
}

function collapseAllPhases() {
  const els = document.querySelectorAll('.phase-card-row')

  for (var i = 0; i < els.length; i++) {
    const class_arr = els[i].classList.value.split(" ")
    const id = class_arr.filter(function(classString) { return classString.includes("phase-") && !classString.includes("phase-card") })[0].split('phase-')[1]
    const el = document.querySelector('.phase-' + id)
    collapsePhase(el)
  }
}

function togglePhase(id, expandable) {
  if (expandable) {
    const expClass = 'expanded'
    const el = document.querySelector('.phase-' + id)
    if(!el) {
      console.warn(`unable to toggle phase ${id}`)
      return
    }

    if (el.classList.contains(expClass)) {
      collapsePhase(el)
    }
    else {
      expandPhase(el)
    }
  }
}

function collapsePhase(el) {
  const expClass = 'expanded'
  el.classList.remove(expClass)
  el.querySelector(".fa-minus-circle").style.display = "none";
  el.querySelector(".fa-plus-circle").style.display = "block";
}

function expandPhase(el) {
  const expClass = 'expanded'
  el.classList.add(expClass)
  el.querySelector(".fa-plus-circle").style.display = "none";
  el.querySelector(".fa-minus-circle").style.display = "block";
}
