import AutoNumeric from 'autonumeric/dist/autoNumeric.min';

document.addEventListener("turbolinks:load", function() {
  updateAutonumerics()
})

const INITIALIZED_CLASS = 'autonumeric-initialized'

export const defaultOptions = {
  emptyInputBehavior: 'null',
  watchExternalChanges: true,
  allowDecimalPadding: 'floats',
  unformatOnSubmit: true
}

export function updateAutonumerics() {
  console.log(`updating all autonumerics`)
  updateCurrencyAutonumerics()
  updatePercentAutonumerics()
}

export function updateCurrencyAutonumerics() {
  document.querySelectorAll('.autonumeric-currency').forEach(($a) => {
    // if has INITIALIZED_CLASS class, skip
    if ($a.classList.contains(INITIALIZED_CLASS)) {
      return
    }

    new AutoNumeric($a, Object.assign({}, defaultOptions, {
      currencySymbol: '$'
    }))

    $a.classList.add(INITIALIZED_CLASS)
  })

  document.querySelectorAll('.autonumeric-nullable-currency').forEach(($a) => {
    // if has INITIALIZED_CLASS class, skip
    if ($a.classList.contains(INITIALIZED_CLASS)) {
      return
    }

    new AutoNumeric($a, Object.assign({}, defaultOptions, {
      currencySymbol: '$',
      emptyInputBehavior: 'null',
    }))

    $a.classList.add(INITIALIZED_CLASS)
  })
}

export function updatePercentAutonumerics() {
  document.querySelectorAll('.autonumeric-percent').forEach(($a) => {
    if ($a.classList.contains(INITIALIZED_CLASS)) {
      return
    }

    new AutoNumeric($a, Object.assign({}, defaultOptions, {
      suffixText: "%"
    }))

    $a.classList.add(INITIALIZED_CLASS)
  })
}
