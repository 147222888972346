// shows/hides elements based on another elements value
// eg: <div class="show-if" data-source="#user_role_id" data-equals="<%= @client_role_id %>">
// eg: <div class="show-if" data-source="#user_role_id" data-does-not-equal="<%= @client_role_id %>">

document.addEventListener("turbolinks:load", function(){
  const HIDE_CLASS = 'hide'

  const $conditionallyShownElements = document.querySelectorAll(".show-if")
  $conditionallyShownElements.forEach(($conditionallyShownElement) => {
    const $sourceInputThatWillChange = document.querySelector($conditionallyShownElement.dataset.source)

    if(!$sourceInputThatWillChange) {
      console.warn(`unable to find source for show-if ${$conditionallyShownElement.dataset.source}`)
      return
    }

    $sourceInputThatWillChange.addEventListener('change', () => {
      updateConditionallyShownElement($conditionallyShownElement)
    })

    updateConditionallyShownElement($conditionallyShownElement)
  })

  function updateConditionallyShownElement($conditionallyShownElement) {
    const $sourceInputThatWillChange = document.querySelector($conditionallyShownElement.dataset.source)

    let valueToTestFromSourceInput = $sourceInputThatWillChange.value
    if($conditionallyShownElement.dataset.attribute) {
      // if it's a select box, we'll actually want to check the data and attributes of the option
      const $elementToCheck = $sourceInputThatWillChange.tagName === 'SELECT' ? $sourceInputThatWillChange.options[$sourceInputThatWillChange.selectedIndex] : $sourceInputThatWillChange

      // see if it's an attribute of the element or if it's on the dataset
      valueToTestFromSourceInput = $elementToCheck[$conditionallyShownElement.dataset.attribute] || $elementToCheck.dataset[$conditionallyShownElement.dataset.attribute]
    }

    // we always want the value to be expressed and tested as it's actual type, eg: boolean true instead of string "true", unless data-no-eval-equals="true"
    // console.log(`valueToTestFromSourceInput (${$conditionallyShownElement.dataset.source}) before assignment with possible eval: ${valueToTestFromSourceInput}`)

    valueToTestFromSourceInput = $sourceInputThatWillChange.dataset.noEval !== undefined ? valueToTestFromSourceInput : eval(valueToTestFromSourceInput)
    // console.log(`valueToTestFromSourceInput (${$conditionallyShownElement.dataset.source}) after assignment with possible eval: ${valueToTestFromSourceInput}`)

    if($conditionallyShownElement.dataset.doesNotEqual !== undefined) {
      const valueToTestFromConditionallyShownElement = $conditionallyShownElement.dataset.noEval ? $conditionallyShownElement.dataset.doesNotEqual : eval($conditionallyShownElement.dataset.doesNotEqual)

      //console.log(`NotEqual set, comparing conditional element vs source: (${valueToTestFromConditionallyShownElement}) to (${valueToTestFromSourceInput})`)
      if(valueToTestFromConditionallyShownElement !== valueToTestFromSourceInput) {
        $conditionallyShownElement.classList.remove(HIDE_CLASS)
      }
      else {
        $conditionallyShownElement.classList.add(HIDE_CLASS)
      }
    }

    if($conditionallyShownElement.dataset.equals !== undefined) {
      const valueToTestFromConditionallyShownElement = $conditionallyShownElement.dataset.noEval !== undefined ? $conditionallyShownElement.dataset.equals : eval($conditionallyShownElement.dataset.equals)

      //console.log(`equals set, comparing conditional element vs source: (${valueToTestFromConditionallyShownElement}) to (${valueToTestFromSourceInput})`)
      if($conditionallyShownElement.dataset.equals && valueToTestFromConditionallyShownElement === valueToTestFromSourceInput) {
        $conditionallyShownElement.classList.remove(HIDE_CLASS)
      }
      else {
        $conditionallyShownElement.classList.add(HIDE_CLASS)
      }
    }
  }
})
