document.addEventListener("turbolinks:load", function(){
  unifyHeight()
})

export function unifyHeight()
{
  let maxHeights = {};

  const $sameHeightItems = $('.same-height');
  $sameHeightItems.each(function() {
    const $sameHeightItem = $(this);
    $sameHeightItem.css('height', 'auto');
    const height = $(this).height();
    const sameHeightGroup = getSameHeightGroup($sameHeightItem)
    maxHeights[sameHeightGroup] = maxHeights[sameHeightGroup] || 0
    if(height > maxHeights[sameHeightGroup]) {
      maxHeights[sameHeightGroup] = height;
    }
  })

  $sameHeightItems.each(function() {
    const $sameHeightItem = $(this);
    const sameHeightGroup = getSameHeightGroup($sameHeightItem)

    $(this).height(maxHeights[sameHeightGroup]);
  });
}

function getSameHeightGroup($item) {
  return $item.data('same-height-group') || '__standard'
}
