import { unifyHeight } from './same_height'
import moment from 'moment'

$(document).on('turbolinks:load', function() {
  const FLATPICKER_DATE_FORMAT = 'MM/DD/YYYY';
  const RAILS_DATE_FORMAT = 'MM/DD/YYYY';

  $('.checkbox-dropdown .dropdown-item').click(function(e) {
    e.stopPropagation();
  });

  const $cardFilterForm = $('#card-filter')

  if(!$cardFilterForm.hasClass('submit-only')) {
    $cardFilterForm.find('input[type="checkbox"], .flatpickr-input').change(function() {
      updateFilterDropdowns()
      updateCards()
      updateURL()
    })

    // prevent hitting the enter button on the seach bar from posting the form
    $cardFilterForm.find('input[type="text"]').keydown(function(e) {
      if(e.key === "Enter") {
        e.preventDefault()
      }
    })

    $cardFilterForm.find('#search').keyup(debounce(function() {
      updateURL()
      updateCards()
    }, 100))

  }

  updateFilterDropdowns()
  updateCards()

  $cardFilterForm.find('.filter .filter-item').each(function() {
    const $filterItem = $(this)

    const $dropdownSearch = $filterItem.find('.filter-dropdown-search')
    $dropdownSearch.keyup(function() {
      updateFilterDropdownItems($filterItem, $dropdownSearch, formAsKeyValuePairs($cardFilterForm))
    })
  })


  function updateCards() {
    const $filteringIndicatorWrapper = $('.filtering-indicator-wrapper')
    const FILTERED_CLASS = 'filtered'

    $filteringIndicatorWrapper.removeClass(FILTERED_CLASS)

    const formValues = formAsKeyValuePairs($cardFilterForm);

    let cardsToShow = []

    $('.filterable-card').each(function() {
      const $card = $(this);

      const cardAEs = Array.from($card.data('aes') || [])?.map((a) => a.toString())
      const cardPMs = Array.from($card.data('pms') || [])?.map((a) => a.toString())
      const cardName = $card.data('name')
      const cardCompany = $card.data('company')?.toString()
      const cardClient = $card.data('client')?.toString()
      const cardStatus = $card.data('status')
      const cardPriority = $card.data('priority')
      const cardPhase = $card.data('phase')?.toString()
      const cardEntryType = $card.data('entry-type')
      const cardProject = $card.data('project')?.toString()
      const cardUser = $card.data('user')?.toString();
      const cardLaborType = $card.data('labor-type')?.toString();
      const cardBillable = $card.data('billable')?.toString();

      const cardStartDate = $card.data('start-date') ? moment($card.data('start-date'), RAILS_DATE_FORMAT) : null
      const cardEndDate = $card.data('end-date') ? moment($card.data('end-date'), RAILS_DATE_FORMAT) : null
      const cardDate = $card.data('date') ? moment($card.data('date'), RAILS_DATE_FORMAT) : null
      const cardDate2 = $card.data('date') ? moment($card.data('date2'), RAILS_DATE_FORMAT) : null


      let matchesFilter = true;
      const searchValue = formValues['search'];
      if(searchValue) {
        matchesFilter = cardName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
      }

      if(matchesFilter && formValues['statuses'] && formValues['statuses'].length > 0) {
        matchesFilter = formValues['statuses'].indexOf(cardStatus) !== -1
      }

      if(matchesFilter && formValues['priorities'] && formValues['priorities'].length > 0) {
        matchesFilter = formValues['priorities'].indexOf(cardPriority) !== -1
      }

      if(matchesFilter && formValues['companies'] && formValues['companies'].length > 0) {
        matchesFilter = formValues['companies'].indexOf(cardCompany) !== -1
      }

      if(matchesFilter && formValues['clients'] && formValues['clients'].length > 0) {
        matchesFilter = formValues['clients'].indexOf(cardClient) !== -1
      }

      if(matchesFilter && cardAEs && formValues['aes'] && formValues['aes'].length > 0) {
        matchesFilter = arrayHasAny(cardAEs, formValues['aes'])
      }

      if(matchesFilter && cardPMs && formValues['pms'] && formValues['pms'].length > 0) {
        matchesFilter = arrayHasAny(cardPMs, formValues['pms'])
      }

      if(matchesFilter && cardPhase && formValues['phases'] && formValues['phases'].length > 0) {
        matchesFilter = formValues['phases'].indexOf(cardPhase) !== -1
      }

      if(matchesFilter && cardEntryType && formValues['entry_types'] && formValues['entry_types'].length > 0) {
        matchesFilter = formValues['entry_types'].indexOf(cardEntryType) !== -1
      }

      if(matchesFilter && formValues['projects'] && formValues['projects'].length > 0) {
        matchesFilter = formValues['projects'].indexOf(cardProject) !== -1
      }

      if(matchesFilter && formValues['users'] && formValues['users'].length > 0) {
        matchesFilter = formValues['users'].indexOf(cardUser) !== -1
      }

      if(matchesFilter && formValues['labor_types'] && formValues['labor_types'].length > 0) {
        matchesFilter = formValues['labor_types'].indexOf(cardLaborType) !== -1
      }

      if(matchesFilter && formValues['billables'] &&  formValues['billables'].length > 0) {
        matchesFilter =  formValues['billables'].indexOf(cardBillable) !== -1
      }

      // start_date submits the form, inline_start_date filters inline without submitting
      if(matchesFilter && formValues['inline_start_date'] && cardStartDate) {
        matchesFilter = cardStartDate.isSameOrAfter(formValues['inline_start_date'])
      }

      // end_date submits the form, inline_end_date filters inline without submitting
      if(matchesFilter && formValues['inline_end_date'] && cardEndDate) {
        matchesFilter =  cardEndDate.isSameOrBefore(formValues['inline_end_date'])
      }

      if(matchesFilter && formValues['inline_start_date'] && formValues['inline_end_date'] && cardDate) {
        matchesFilter = cardDate.isSameOrAfter(formValues['inline_start_date']) && cardDate.isSameOrBefore(formValues['inline_end_date'])
      }

      // start_date submits the form, inline_start_date filters inline without submitting
      if(matchesFilter && formValues['inline_start_date2'] && cardStartDate2) {
        matchesFilter = cardStartDate2.isSameOrAfter(formValues['inline_start_date2'])
      }

      // end_date submits the form, inline_end_date filters inline without submitting
      if(matchesFilter && formValues['inline_end_date2'] && cardEndDate2) {
        matchesFilter =  cardEndDate2.isSameOrBefore(formValues['inline_end_date2'])
      }

      if(matchesFilter && formValues['inline_start_date2'] && formValues['inline_end_date2'] && cardDate2) {
        matchesFilter = cardDate2.isSameOrAfter(formValues['inline_start_date2']) && cardDate2.isSameOrBefore(formValues['inline_end_date2'])
      }

      if(matchesFilter) {
        $card.show();

        cardsToShow.push($card)
      }
      else {
        $card.hide();
      }
    })

    const filterEvent = new CustomEvent('cardsFiltered', { detail: { cards: cardsToShow } })
    window.dispatchEvent(filterEvent)

    updateFilterSummaryIndicator()

    // this has to be done before we compress or the fact that the whole section is hidden will
    // compress every section
    $filteringIndicatorWrapper.addClass(FILTERED_CLASS)

    $('.compressable').each(function(){
      const $compressable = $(this)
      const $elementToChecks = $compressable.find($compressable.data('via'));
      let totalHours = 0;
      let totalApprovedDollars = 0;
      let totalUnapprovedDollars = 0;
      let totalDollars = 0;
      let compressStatus = $compressable.find('.h-col-one').children().text().split("-")[0];

      $compressable.show();

      let totalHeight = 0
      $elementToChecks.each(function() {
        totalHeight += $(this).height()
      })

      if(totalHeight === 0) {
        $compressable.hide();
      }
    })

    unifyHeight();
  }

  function updateFilterDropdowns() {
    const formValues = formAsKeyValuePairs($cardFilterForm);

    $('.filter .filter-item').each(function() {
      const $filterItem = $(this)

      updateFilterDropdownItems($filterItem, $filterItem.find('.filter-dropdown-search'), formValues)
    })
  }

  function updateFilterDropdownItems($filterItem, $dropdownSearch, formValues) {
    $filterItem.find('.dropdown-item').each(function() {
      const $dropdownItem = $(this)
      const itemStartDate = $dropdownItem.data('items-start-date') ? moment($dropdownItem.data('items-start-date'), RAILS_DATE_FORMAT) : null
      const itemEndDate = $dropdownItem.data('items-end-date') ? moment($dropdownItem.data('items-end-date'), RAILS_DATE_FORMAT) : null
      const itemClient = $dropdownItem.data('client')
      const itemCompany = $dropdownItem.data('company')

      let matchesFilter = true

      if(itemStartDate && itemEndDate && formValues['inline_start_date'] && formValues['inline_end_date']) {
        const datesDontOverlap = itemEndDate.isBefore(formValues['inline_start_date']) || itemStartDate.isAfter(formValues['inline_end_date'])
        matchesFilter = !datesDontOverlap
      }

      if(matchesFilter && itemClient !== undefined && formValues['clients'] && formValues['clients'].length > 0) {

        matchesFilter = formValues['clients'].indexOf(itemClient.toString()) !== -1
      }

      if(matchesFilter && itemCompany !== undefined && formValues['companies'] && formValues['companies'].length > 0) {

        matchesFilter = formValues['companies'].indexOf(itemCompany.toString()) !== -1
      }

      if(!matchesFilter) {
        $dropdownItem.find("input[type=checkbox]").prop('checked', false)
      }

      if(matchesFilter && $dropdownSearch && $dropdownSearch.length) {
        const $label = $dropdownItem.find('.form-check-label')

        matchesFilter = $label.text().toLowerCase().indexOf($dropdownSearch.val().toLowerCase()) !== -1
      }

      if(matchesFilter){
        $dropdownItem.show()
      }
      else {
        $dropdownItem.hide()
      }
    })

    updateFilterSummaryIndicator()
  }


  function updateFilterSummaryIndicator() {
    const filterItems = []
    $('.filter .filter-item').each(function() {
      const $filterItem = $(this)

      const filtersSelected = []
      $filterItem.find("input[type=checkbox]:checked").each(function(){
        const $checked = $(this)

        // gets the label associated with the checkbox
        const labelForCheckbox = $checked.prop("labels")[0];
        filtersSelected.push($.trim(labelForCheckbox.innerText))
      })

      // put and " or" on the end of the last filter in a group
      if(filtersSelected.length > 0){
        let filterItemText = $filterItem.find(".dropdown-toggle").text()
        filterItemText = $.trim(filterItemText.replace("Select ", ""))

        let html = ""
        filtersSelected.forEach(filterSelected => {
          html += `<span class="badge">${filterSelected}</span>`
        });

        filterItems.push(`${filterItemText}: ${html}`)
      }
    })

    $("#filters-selected").html(filterItems.join(""))
  }

  function updateURL() {
    let url = window.location.href
    window.history.pushState('', '', '?' + $("#card-filter").serialize() + '&' + addDateRangeToURL(url));

    const updateOnPushInputs = document.querySelectorAll(".update-url-on-push")
    if(updateOnPushInputs && updateOnPushInputs.length > 0) {
      updateOnPushInputs.each((el) => el.value = window.location.href)
    }
  }

  function addDateRangeToURL(url){
    let newAdditionalURL = ""
    let urlParts = url.split("?");
    let additionalURL = urlParts[1];
    let possibleAmpersand = "";

    if (additionalURL) {
      urlParts = additionalURL.split("&");
      for (var i=0; i<urlParts.length; i++){
        if(urlParts[i].split('=')[0] == 'start_date' || urlParts[i].split('=')[0] == 'end_date'){
          newAdditionalURL += possibleAmpersand + urlParts[i];
          possibleAmpersand = "&";
        }
      }
    }

    return newAdditionalURL;
  }

  function arrayHasAny(array1, array2) {
    // use for instead of each so we can break as soon as it's found
    for(var i = 0; i < array1.length; i++) {
      const array1Value = array1[i];
      if(array2.indexOf(array1Value) !== -1) {
        return true;
      }
    }

    return false;
  }

  function formAsKeyValuePairs($form) {
    let formValues = {};
    $.each($form.serializeArray(), function() {
      if(this.name.indexOf('[]') !== -1) {
        const key = this.name.replace('[]', '')
        formValues[key] = formValues[key] || []
        formValues[key].push(this.value)
      }
      else if(this.name.indexOf('date') !== -1) {
        formValues[this.name] = moment(this.value, FLATPICKER_DATE_FORMAT)
      }
      else {
        formValues[this.name] = this.value;
      }
    });

    return formValues;
  }

  function debounce(callback, ms) {
    var timer = 0;
    return function() {
      var context = this, args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, ms || 0);
    };
  }
});
