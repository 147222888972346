document.addEventListener("turbolinks:load", function(){
  const $commentContainers = document.querySelectorAll(".comment-container")
  $commentContainers.forEach(($commentContainer) => {
    const $toggleEditModeButtons = $commentContainer.querySelectorAll('.toggle-edit-mode')
    $toggleEditModeButtons.forEach(($toggleEditModeButton) => {
      $toggleEditModeButton.addEventListener('click', () => {
        $commentContainer.classList.toggle('-edit-mode')
      })
    })
  })
})
