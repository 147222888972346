const RED_TEXT = 'text-danger';
const GREEN_TEXT = 'text-success';
const BLACK_TEXT = 'text-black';

$(document).on('turbolinks:load', function() {
  const avgPastDueExists = document.getElementById("average_past_due")
  const avgUnderOverBudgetExists = document.getElementById("average_under_over_budget")
  const avgEffectiveHourlyExists = document.getElementById("average_effective_hourly_rate")
  const avgPercentageProfitExists = document.getElementById("average_percentage_profit")
  const avgPhaseProfitExists = document.getElementById("average_phase_profit")
  textColor(avgPastDueExists, "number");
  textColor(avgUnderOverBudgetExists, "currency");
  textColor(avgEffectiveHourlyExists, "currency");
  textColor(avgPercentageProfitExists, "percentage");
  textColor(avgPhaseProfitExists, "currency");
  window.addEventListener('cardsFiltered', function (e) {
    let averagePastDue = 0
    let averageUnderOverBudget = 0
    let sumPastDue = 0;
    let sumOverUnderBudget = 0;
    let avgEffectiveHourly = 0;
    let sumEffectiveHourly = 0;
    let avgPercentageProfit = 0;
    let sumPercentageProfit = 0;
    let avgPhaseProfit = 0;
    let sumPhaseProfit = 0;
    let count = 0
    e.detail.cards.forEach(($card) => {
      const cardPastDue = $card.data('days-past-due')
      const cardOverUnderBudget = $card.data('hours-over-under-budget')
      const cardEffectiveHourlyRate = $card.data('effective-hourly-rate')
      const cardPercentageProfit = $card.data('percentage-profit')
      const cardPhaseProfit = $card.data('phase-profit')

      sumPastDue += cardPastDue;
      sumOverUnderBudget += parseFloat(cardOverUnderBudget);
      sumEffectiveHourly += parseFloat(cardEffectiveHourlyRate);
      sumPercentageProfit += parseFloat(cardPercentageProfit/100);
      sumPhaseProfit += parseFloat(cardPhaseProfit);

      count++
    })

    if(avgPastDueExists){
      averagePastDue = (sumPastDue/count).toFixed(2);
      avgPastDueExists.innerText = averagePastDue;
      textColor(avgPastDueExists, "days");
    }

    if(avgUnderOverBudgetExists){
      averageUnderOverBudget = (sumOverUnderBudget/count).toFixed(2)
      avgUnderOverBudgetExists.innerText = averageUnderOverBudget;
      textColor(avgUnderOverBudgetExists, "number");
    }

    if(avgEffectiveHourlyExists){
      avgEffectiveHourly = (sumEffectiveHourly/count).toFixed(2)
      avgEffectiveHourlyExists.innerText = "$" + avgEffectiveHourly;
      textColor(avgEffectiveHourlyExists, "currency");
    }

    if(avgPercentageProfitExists){
      avgPercentageProfit = ((sumPercentageProfit/count)*100).toFixed(0)
      avgPercentageProfitExists.innerText = avgPercentageProfit + "%";
      textColor(avgPercentageProfitExists, "percentage");
    }

    if(avgPhaseProfitExists){
      avgPhaseProfit = (sumPhaseProfit/count).toFixed(2)
      avgPhaseProfitExists.innerText = "$" + avgPhaseProfit;
      textColor(avgPhaseProfitExists, "currency");
    }
  }, false)
})

function textColor(card,type){
  if(!card){
    return;
  }
  let value = card.innerText.replace(/[$%,]/g, "");
  value = parseFloat(value);
  if(type == "currency" || type == "percentage"){
    if(value > 0){
      card.classList.remove(RED_TEXT)
      card.classList.add(GREEN_TEXT)
      card.classList.remove(BLACK_TEXT)
    }else if(value < 0){
      card.classList.add(RED_TEXT)
      card.classList.remove(GREEN_TEXT)
      card.classList.remove(BLACK_TEXT)
    }else{
      card.classList.remove(RED_TEXT)
      card.classList.remove(GREEN_TEXT)
      card.classList.add(BLACK_TEXT)
    }
  }else if(type == "number"){
    if(value > 0){
      card.classList.remove(RED_TEXT)
      card.classList.add(GREEN_TEXT)
      card.classList.remove(BLACK_TEXT)
    }else if(value < 0){
      card.classList.add(RED_TEXT)
      card.classList.remove(GREEN_TEXT)
      card.classList.remove(BLACK_TEXT)
    }else{
      card.classList.remove(RED_TEXT)
      card.classList.remove(GREEN_TEXT)
      card.classList.add(BLACK_TEXT)
    }
  }else if(type == "days"){
    if(value < 0){
      card.classList.remove(RED_TEXT)
      card.classList.add(GREEN_TEXT)
      card.classList.remove(BLACK_TEXT)
    }else if(value > 0){
      card.classList.add(RED_TEXT)
      card.classList.remove(GREEN_TEXT)
      card.classList.remove(BLACK_TEXT)
    }else{
      card.classList.remove(RED_TEXT)
      card.classList.remove(GREEN_TEXT)
      card.classList.add(BLACK_TEXT)
    }
  }
}

