import Turbolinks from "turbolinks"
Turbolinks.start()
import './turbolinks_helpers'

import Rails from "rails-ujs";
Rails.start();

import 'tether/dist/js/tether';
import 'bootstrap/dist/js/bootstrap'
import 'trix/dist/trix.js'
import 'flatpickr/dist/flatpickr'
import '../../../public/semantic/semantic'
import 'chartkick/chart.js'
import '@nathanvda/cocoon'

import './card_filter'
import './comments'
import './company_to_projects_updater'
import './csrf_token'
import './datepicker'
import './execution_log'
import './expandable_card'
import './invoice_requests'
import './phases'
import './reporting'
import './same_height'
import './show_if'
import './semantic_ui'
import './cocoon'
